import React from "react";
import {Box, Button, ButtonGroup, Container, HStack, Link,} from '@chakra-ui/react'

export const NavbarWithPopoverOnAccent = ({overlay}: { overlay?: boolean }) => (
    <Box as="section" position={overlay ? 'absolute' : 'initial'} color={overlay ? 'white' : 'initial'}>
        <Box borderBottomWidth="1px" bg="transparent" position="relative" zIndex="tooltip"
             borderBottom={overlay ? 'none' : 'initial'}>
            <Container py="4" minW={'full'}>
                <HStack justify="space-between" spacing="8">
                    <HStack spacing="10">
                        {/*<HStack spacing="3">*/}
                        {/*    <MobileDrawer/>*/}
                        {/*    <Logo/>*/}
                        {/*</HStack>*/}
                        <ButtonGroup
                            size="lg"
                            variant="text.accent"
                            colorScheme="gray"
                            spacing="8"
                            display={{base: 'none', lg: 'flex'}}
                        >
                            {/*<Button>About</Button>*/}
                            {/*<Button>Contact</Button>*/}
                            <Button as={Link} href={'/'}>Home</Button>
                            <Button as={Link} href={'/blog'}>Blog</Button>
                            <Button as={Link} href={'/contact'}>Contact</Button>
                        </ButtonGroup>
                    </HStack>
                    <HStack spacing={{base: '2', md: '4'}}>

                    </HStack>
                </HStack>
            </Container>
        </Box>
    </Box>
)
