import {Accordion, Box, Container, Heading, Image, SimpleGrid, Stack, Text} from '@chakra-ui/react'
import {JobPosting} from './JobPosting'
// @ts-ignore
import image from '../../assets/bed-divider2.webp'
import {jobListings} from "./data";
export const CareersWithAccordion = () => (
    <Container py={{base: '16', md: '24'}} minW={'full'}>
        <Box maxW={{ base: 'xl', md: '7xl' }} mx={'auto'}>
            <SimpleGrid columns={{base: 1, md: 2}} gap={12}>
                <Stack spacing={{base: '4', md: '6'}}>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Revolutionize Your Sleep with HealthShield Bed
                            Divider</Heading>
                        <Text>
                            When you share your life and bed with someone, their health issues, like
                            falling sick or snoring, can become yours. That's where HealthShield Bed Divider
                            steps in – a groundbreaking solution designed to bring tranquility and health
                            safety back into your shared sleeping space.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Say Goodbye to Sleepless Nights Due to Snoring</Heading>
                        <Text>
                            Snoring isn't just a nuisance; it's a barrier to a good night's sleep. Our
                            HealthShield Bed Divider is engineered to provide an effective barrier against
                            the disruptive sound of snoring. It's not just about reducing noise; it's about
                            enhancing the quality of your sleep.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Protect Your Health with Illness Isolation</Heading>
                        <Text>
                            Sharing a bed shouldn't mean sharing germs. Whether it's a common cold, flu,
                            or any other contagious illness, our bed divider acts as a shield, minimizing
                            the spread of germs. With HealthShield, you can sleep beside your partner
                            without the worry of falling ill.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Designed for Comfort and Convenience</Heading>
                        <Text>
                            We believe that practical solutions should never compromise on comfort. That's
                            why HealthShield is designed with soft, breathable materials that ensure
                            comfort throughout the night. Its easy-to-install design means you can set it
                            up or remove it effortlessly, making it a convenient addition to any bedroom.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">A Blend of Innovation and Elegance</Heading>
                        <Text>
                            Our product isn't just functional; it's also aesthetically pleasing. With its
                            sleek design, HealthShield blends seamlessly into your bedroom décor. It's more
                            than a utility; it's a part of your intimate space, designed to enhance both its
                            function and form.
                        </Text>
                    </Stack>
                    <Stack spacing={2}>
                        <Heading as="h4" fontSize="22px">Trusted by Couples Worldwide</Heading>
                        <Text>
                            Don't just take our word for it. HealthShield has helped countless couples find
                            peace and health in their shared sleeping environments. From reducing snoring
                            disturbances to providing a barrier against illness, our customers have
                            experienced firsthand the benefits of HealthShield.
                        </Text>
                    </Stack>
                </Stack>
                <Accordion defaultIndex={0}>
                    <Image src={image} w={'full'}/>
                    <Heading as="h4" fontSize="26px" mb={6} mt={10}>FAQ</Heading>
                    {jobListings.map((listing, id) => (
                        <JobPosting key={id} {...listing} />
                    ))}
                </Accordion>
            </SimpleGrid>
        </Box>
    </Container>
)
