import {FormControl, FormControlProps, FormLabel, HStack, useRadioGroup, UseRadioGroupProps,} from '@chakra-ui/react'
import {ColorPickerOption} from './ColorPickerOption'
import React from "react";

interface ColorPickerProps extends UseRadioGroupProps {
    options: Option[]
    rootProps?: FormControlProps
    hideLabel?: boolean
    label?: string
    updateSlider: (color: string) => any
}

interface Option {
    label: string
    value: string
}

export const ColorPicker = (props: ColorPickerProps) => {
    const {options, rootProps, hideLabel, label, updateSlider, ...rest} = props
    const {getRadioProps, getRootProps, value} = useRadioGroup(rest)
    const selectedOption = options.find((option) => option.value === value)
    return (
        <FormControl {...rootProps}>
            {!hideLabel && (
                <FormLabel fontSize="sm" fontWeight="medium">
                    {label ?? `Color: ${selectedOption?.label ?? '-'}`}
                </FormLabel>
            )}
            <HStack {...getRootProps()}>
                {options.map((option) => (
                    <ColorPickerOption
                        updateSlider={updateSlider}
                        key={option.label}
                        color={option.value}
                        {...getRadioProps({value: option.value})}
                    />
                ))}
            </HStack>
        </FormControl>
    )
}
