import * as React from "react";
import {Helmet} from "react-helmet";
// @ts-ignore
import metaImage from '../../assets/BedDivider_Lifestyle-_White.webp';
import {useLocation} from "react-router-dom";

// Define the type for the props that you expect
interface SEOProps {
    title?: string;
}

const SEO: React.FC<SEOProps> = ({title}) => {
    let url = '';

    // TypeScript-safe check for window object
    if (typeof window !== 'undefined') {
        url = window.location.href;
    }

    // Updated to a TypeScript arrow function with explicit return type
    const stripTrailingSlash = (str: string): string => {
        return str.replace(/\/$/, ''); // Replace trailing slash with an empty string
    };

    const location = useLocation();
    const cUrl = `https://www.beddividerforcouples.com${location.pathname}`;
    const canonicalUrl = cUrl.replace(/\/?$/, '/');


    return (
        <Helmet htmlAttributes={{lang: "en"}} title={`${title ? title : 'Bed Divider For Couples'}`}>
            <meta name="image" content={metaImage}/>

            {/* Facebook Card */}
            <meta property="og:url" content={'www.beddividerforcouples.com'}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={`${title} | Bed Divider For Couples`}/>
            <meta property="og:description"
                  content={'The specialized bed divider for couples. Expertly designed for effective snore reduction and illness isolation, it\'s the ideal solution for maintaining health and comfort in shared sleeping spaces. Perfect for situations where one partner is sick or snores. Shop now for a safer, more peaceful night\'s sleep!'}/>
            <meta property="og:image" content={metaImage}/>
            <meta property="og:image:width" content="400"/>
            <meta property="og:image:height" content="300"/>

            {/* Twitter Card */}
            <meta name="twitter:card" content="summary_large_image"/>
            {/* Uncomment and fill in the values if you have them */}
            {/*<meta name="twitter:creator" content={twitterUsername}/>*/}
            {/*<meta name="twitter:title" content={siteTitle}/>*/}
            {/*<meta name="twitter:description" content={siteDesc}/>*/}
            <meta name="twitter:image" content={`${stripTrailingSlash(url)}${metaImage}`}/>
            <link rel="canonical" href={canonicalUrl}/>
        </Helmet>
    );
}

export default SEO;
