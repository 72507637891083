import {NavbarWithPopoverOnAccent} from "../components/NavbarWithPopoverOnAccent/NavbarWithPopoverOnAccent";
import SEO from "../components/SEO/Seo";
import {WithImageBackground} from "../components/WithImageBackground/WithImageBackground";
import {ProductCarosel} from "../components/ProductCarosel/ProductCarosel";
import {ImageText} from "../components/ImageText/ImageText";
import image4 from "../assets/BedDivider_Lifestyle-_White.webp";
import image3 from "../assets/BedDivider_Flat.webp";
import {ImageCta} from "../components/ImageCta/ImageCta";
import image from "../assets/BedDivider_Lifestyle-_DarkGrey.webp";
import {CareersWithAccordion} from "../components/CareersWithAccordion/CareersWithAccordion";
import image2 from "../assets/BedDivider_Lifestyle-_Grey.webp";
import {FooterWithSocialIcons} from "../components/FooterWithSocialIcons/FooterWithSocialIcons";
import {PopupWithDiscount} from "../components/PopupWithDiscount/PopupWithDiscount";
import React, {useState} from "react";
import {Box, useDisclosure} from "@chakra-ui/react";

export const Home = () => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [color, setColor] = useState('')
    const [size, setSize] = useState('')
    return (
        <Box>
            <NavbarWithPopoverOnAccent overlay={true}/>
            <SEO title={'Bed Divider For Couples'}/>
            <WithImageBackground onOpen={onOpen} useH1={true}/>
            <ProductCarosel onOpen={onOpen} setColor={setColor} setSize={setSize}/>
            <ImageText img={image4} heading={`Sleek design in a variety of colors`} onOpen={onOpen}
                       orderBase={'column'} orderMd={'row-reverse'}
                       subheading={`Enhance your sleep health and bedroom style with our versatile Bed Divider. Available in multiple colors, it's designed for a stress-free, peaceful night's sleep. Ideal for maintaining mental well-being, our stylish Bed Divider ensures restful sleep. Its diverse color options perfectly complement any bedroom decor, promoting both sleep hygiene and interior elegance. Choose our Bed Divider for improved sleep quality and a fashionable bedroom upgrade. Sleep better and stay trendy with our color-rich, health-focused Bed Divider.`}
            />
            <ImageText img={image3} heading={`Portable for easy use and storage`} onOpen={onOpen} orderBase={'column'}
                       orderMd={'row'}
                       subheading={`Our innovative Bed Divider offers a convenient solution for couples. It easily folds flat, ensuring hassle-free storage and quick accessibility whenever needed. Especially beneficial when your partner is unwell, this divider provides a safe barrier, reducing your worries about getting sick. Its compact design allows for effortless handling and space-saving storage, making it an ideal addition to maintain health and comfort in shared sleeping spaces. Choose our Bed Divider for peace of mind and practicality in your bedroom.`}
            />
            <ImageCta onOpen={onOpen} heading={'Ultimate Couples Bed Divider Minimizing Germs & Sounds'}
                      subheading={'Ease Your Mind with a Solution for Snoring & Illness Prevention for Couples Who Sleep\n' +
                          '                        Together'} image={image} bg={'blackAlpha.600'}/>

            <CareersWithAccordion/>
            <ImageCta onOpen={onOpen} heading={'Ultimate Couples Bed Divider Minimizing Germs & Sounds'}
                      subheading={'Ease Your Mind with a Solution for Snoring & Illness Prevention for Couples Who Sleep\n' +
                          '                        Together'} image={image2} bg={'blackAlpha.600'}/>

            <FooterWithSocialIcons/>
            <PopupWithDiscount isOpen={isOpen} onClose={onClose} color={color} size={size}/>
        </Box>
    )
}