import {Box, Button, Flex, Heading, Img, Stack, Text} from '@chakra-ui/react'
// @ts-ignore
import image from '../../assets/BedDivider_ProductBanner_Grey.webp'
import React from "react";
import {PriceTag} from "../ProductCarosel/PriceTag";

export const WithImageBackground = ({onOpen, useH1}: { onOpen: any, useH1?: boolean }) => {
    const handleBuyNowClick = () => {
        // Assuming onOpen is some function you want to run when the button is clicked
        onOpen();

        // Pushing an event to GTM's data layer
        if ((window as any).dataLayer) {
            (window as any).dataLayer.push({
                event: 'buy_now_clicked', // Event name. This is what GTM will listen for
                product: 'Ultimate Couples Bed Divider', // Optional: Any additional data you want to send
                // ... you can add more properties here
            });
        }
    };

    return (
        <Box bg="gray.800" as="section" minH="140px" position="relative">
            <Box py={{base: 16, lg: 32}} pb={{base: 8, lg: 32}} position="relative" zIndex={1}>
                <Box maxW={{base: 'xl', md: '7xl'}} mx="auto" px={{base: '6', md: '8'}} color="white">
                    <Box maxW="xl">
                        {/*<Logo zIndex={'10000'} pos={'absolute'} top={'10px'} left={'10px'} maxW={'300px'}/>*/}
                        <Heading as={useH1 ? 'h1' : 'h2'} size="3xl" fontWeight="extrabold">
                            Ultimate Bed Divider for Couples Minimizing Germs & Illness
                        </Heading>
                        <PriceTag price={49} currency="USD" rootProps={{fontSize: '4xl', mt: '2'}}
                                  priceProps={{color: "#fff", fontWeight: 'bold'}}/>
                        <Text fontSize={{md: '2xl'}} mt="4" maxW="lg">
                            Bed Divider for Couples: Creates a safer and separate sleep zone when your partner is sick
                        </Text>
                        <Stack direction={{base: 'column', md: 'row'}} mt="10" spacing="4">
                            <Button
                                bg="blue.700"
                                color={'white'}
                                px="24"
                                py="7"
                                rounded="full"
                                size="lg"
                                fontSize="1.5rem"
                                fontWeight="bold"
                                _hover={{
                                    bg: 'blue.800',
                                    cursor: 'pointer'
                                }}
                                onClick={handleBuyNowClick}
                            >
                                Buy Now
                            </Button>
                            {/*<HStack*/}
                            {/*    transition="background 0.2s"*/}
                            {/*    justify={{base: 'center', md: 'flex-start'}}*/}
                            {/*    color="white"*/}
                            {/*    rounded="full"*/}
                            {/*    fontWeight="bold"*/}
                            {/*    px="6"*/}
                            {/*    py="3"*/}
                            {/*    _hover={{bg: 'whiteAlpha.300'}}*/}
                            {/*    onClick={handleJoinListClick}*/}
                            {/*>*/}
                            {/*    <span>Join the list</span>*/}
                            {/*    <HiChevronRight/>*/}
                            {/*</HStack>*/}
                        </Stack>
                    </Box>
                </Box>
            </Box>
            <Flex
                id="image-wrapper"
                position="absolute"
                insetX="0"
                insetY="0"
                w="full"
                h="full"
                overflow="hidden"
                align="center"
            >
                <Box position="relative" w="full" h="full">
                    <Img
                        src={image}
                        alt="Bed Divider For Couples"
                        w="full"
                        h="full"
                        objectFit="cover"
                        objectPosition="66%"
                        position="absolute"
                    />
                    <Box position="absolute" w="full" h="full" bg="blackAlpha.500"/>
                </Box>
            </Flex>
        </Box>
    )
}
