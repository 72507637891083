import {FormControl, FormControlProps, FormLabel, useRadioGroup, UseRadioGroupProps, VStack,} from '@chakra-ui/react'
import {SizePickerButton} from './SizePickerButton'
import React from "react";

interface Option {
    label: string
    value: string
}

interface SizePickerProps extends UseRadioGroupProps {
    options: Option[]
    rootProps?: FormControlProps
    hideLabel?: boolean
    label?: string
    setSize: (v: string) => any
}

export const SizePicker = (props: SizePickerProps) => {
    const {options, rootProps, hideLabel, label, setSize, ...rest} = props
    const {getRadioProps, getRootProps, value} = useRadioGroup(rest)
    const selectedOption = options.find((option) => option.value === value)

    return (
        <FormControl {...rootProps}>
            {!hideLabel && (
                <FormLabel fontSize="sm" fontWeight="medium">
                    {(label && selectedOption?.label) ?? `Size: ${selectedOption?.label ? selectedOption?.label : ''}`}
                </FormLabel>
            )}
            <VStack {...getRootProps()} alignItems={'start'} display={'flex'} flexDirection={'row'}>
                {options.map((option) => (
                    <SizePickerButton
                        setSize={setSize}
                        key={option.value}
                        label={option.label}
                        {...getRadioProps({value: option.value})}
                    />
                ))}
            </VStack>
        </FormControl>
    )
}
