// @ts-ignore
import image from '../../assets/BedDivider_Lifestyle-_Grey.webp'
// @ts-ignore
import image1 from '../../assets/grey.webp'
// @ts-ignore
import image2 from '../../assets/lightgrey.webp'
// @ts-ignore
import image3 from '../../assets/white.webp'
// @ts-ignore
import image4 from '../../assets/teal.webp'
// @ts-ignore
import image5 from '../../assets/green.webp'

export const images = [
    {
        id: '01',
        src: image,
        alt: 'lifestyle bed divider for couples',
    },
    {
        id: '02',
        src: image1,
        alt: 'lifestyle bed divider for couples white',
    },
    {
        id: '03',
        src: image2,
        alt: 'lifestyle bed divider for couples light grey',
    },
    {
        id: '04',
        src: image3,
        alt: 'lifestyle bed divider for couples grey',
    },
    {
        id: '05',
        src: image4,
        alt: 'lifestyle bed divider for couples teal',
    },
    {
        id: '06',
        src: image5,
        alt: 'lifestyle bed divider for couples green',
    },

]

export interface ColorOption {
    label: string;
    value: string
}

export const ColorOptions: ColorOption[] = [
    {label: 'Dark Gray', value: '#666'},
    {label: 'Light Gray', value: '#BBB'},
    {label: 'White', value: '#FFF'},
    {label: 'Teal', value: '#329490'},
    {label: 'Green', value: '#156708'},
]

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
        infer ElementType
    >
    ? ElementType
    : never

export type ProductImage = ElementType<typeof images>
