import {
    AspectRatio,
    Box,
    Button,
    Circle,
    Heading,
    HStack,
    IconButton,
    IconButtonProps,
    Image,
    Skeleton,
    Stack,
    Text,
    useColorModeValue,
} from '@chakra-ui/react'
import {Rating} from './Rating'
import {ColorPicker} from './ColorPicker'
import {PriceTag} from './PriceTag'
import {QuantityPicker} from './QuantityPicker'
import {SizePicker} from "./SizePicker";
import {ColorOption, ColorOptions, images} from './_data'
import React, {useState} from "react";
import {Carousel, CarouselSlide, useCarousel} from "./Carousel";
import {IoChevronBackOutline, IoChevronForwardOutline} from "react-icons/io5";
import LogRocket from "logrocket";

export const ProductCarosel = ({
                                   onOpen,
                                   setColor,
                                   setSize
                               }: { onOpen: any, setColor: (val: string) => any, setSize: (val: string) => any }) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [ref, slider] = useCarousel({
        slideChanged: (slider) => {
            setCurrentSlide(slider.track.details.rel)
        },
    })

    const findColorIndex = (colorValue: string) => {
        return ColorOptions.findIndex(color => color.value === colorValue);
    }
    const updateSlider = (color: string) => {
        const colorToStr = ColorOptions.find((c:ColorOption) => c?.value === color)
        colorToStr?.label && setColor(colorToStr?.label)
        slider.current?.moveToIdx(findColorIndex(color) + 1)
    }

    const hasPrevious = currentSlide !== 0
    const hasNext = currentSlide < images.length - 1

    const handleAddToCart = () => {
        // Assuming onOpen is some function you want to run when the button is clicked
        onOpen();
        LogRocket.track('add_to_cart_clicked');
        // Pushing an event to GTM's data layer
        if ((window as any).dataLayer) {
            (window as any).dataLayer.push({
                event: 'add_to_cart_clicked', // Event name. This is what GTM will listen for
                product: 'Ultimate Couples Bed Divider', // Optional: Any additional data you want to send
                // ... you can add more properties here
            });
        }
    };
    return (
        <Box
            maxW="7xl"
            mx="auto"
            px={{base: '4', md: '0', lg: '0'}}
            py={{base: '6', md: '8', lg: '12'}}
        >
            <Stack
                direction={{base: 'column-reverse', lg: 'row'}}
                spacing={{base: '6', lg: '12', xl: '16'}}
            >
                <Stack spacing={{base: '6', lg: '8'}} maxW={{lg: 'sm'}} justify="center">
                    <Stack spacing={{base: '3', md: '4'}}>
                        <Stack spacing="3">
                            <HStack alignSelf="baseline">
                                <Rating defaultValue={5} size="sm"/>
                                {/*<Link*/}
                                {/*    href="#"*/}
                                {/*    fontSize="sm"*/}
                                {/*    fontWeight="medium"*/}
                                {/*    color={useColorModeValue('gray.600', 'gray.400')}*/}
                                {/*>*/}
                                {/*    12 Reviews*/}
                                {/*</Link>*/}
                            </HStack>
                            <Heading size="lg" fontWeight="medium">
                                Sleep Divider
                            </Heading>
                        </Stack>
                        <PriceTag price={49} currency="USD" rootProps={{fontSize: 'xl'}}/>
                        <Text color={useColorModeValue('gray.600', 'gray.400')}>
                            With a sleek design and a captivating essence, this is a modern Classic made for every
                            occasion.
                        </Text>
                    </Stack>
                    <Stack direction={{base: 'column', md: 'row'}} spacing={{base: '6', md: '8'}}>
                        <Stack flex="1">
                            <ColorPicker
                                defaultValue="Black"
                                options={ColorOptions}
                                updateSlider={updateSlider}
                            />
                            {/*<HStack spacing="1" color={useColorModeValue('gray.600', 'gray.400')}>*/}
                            {/*    <Icon as={FiClock}/>*/}
                            {/*    <Text fontSize="xs" fontWeight="medium">*/}
                            {/*        Low stock*/}
                            {/*    </Text>*/}
                            {/*</HStack>*/}
                        </Stack>
                    </Stack>
                    <Stack flex="1">
                        <SizePicker
                            defaultValue="32"
                            setSize={setSize}
                            options={[
                                {label: '20" L x 6" W x 12" H"', value: 'Standard'},
                                {label: '24" L x 8" W x 15" H"', value: 'Large'},
                            ]}
                        />
                        {/*<HStack spacing="1" color={useColorModeValue('gray.600', 'gray.400')}>*/}
                        {/*    <Icon as={RiRulerLine}/>*/}
                        {/*    <Link href="#" fontSize="xs" fontWeight="medium" textDecoration="underline">*/}
                        {/*        View our sizing guide*/}
                        {/*    </Link>*/}
                        {/*</HStack>*/}
                    </Stack>
                    <HStack spacing={{base: '4', md: '8'}} align="flex-end" justify="space-evenly">
                        <Box flex="1">
                            <QuantityPicker defaultValue={1} max={3}/>
                        </Box>
                        <Box flex="1">
                            {/*<Button*/}
                            {/*    variant="outline"*/}
                            {/*    size="lg"*/}
                            {/*    fontSize="md"*/}
                            {/*    width="full"*/}
                            {/*    leftIcon={<Icon as={FiHeart} boxSize="4"/>}*/}
                            {/*>*/}
                            {/*    Favorite*/}
                            {/*</Button>*/}
                        </Box>
                    </HStack>
                    <Button colorScheme="blue" size="lg" onClick={handleAddToCart} rounded="full" bg="blue.700" py={7}>
                        Add to cart
                    </Button>
                </Stack>
                <Stack spacing="4" {...{overflow: 'hidden', flex: '1'}}>
                    <Box
                        position="relative"
                        sx={{
                            _hover: {
                                '> button': {
                                    display: 'inline-flex',
                                },
                            },
                        }}
                    >
                        <Carousel ref={ref}>
                            {images.map((image, i) => (
                                <CarouselSlide key={i}>
                                    <AspectRatio
                                        ratio={4 / 3}
                                        transition="all 200ms"
                                        opacity={currentSlide === i ? 1 : 0.4}
                                        _hover={{opacity: 1}}
                                    >
                                        <Image src={image.src} objectFit="cover" alt={image.alt}
                                               onLoad={() => slider.current?.update()} fallback={<Skeleton/>}/>
                                    </AspectRatio>
                                </CarouselSlide>
                            ))}
                        </Carousel>
                        {hasPrevious && (
                            <CarouselIconButton
                                pos="absolute"
                                left="3"
                                top="50%"
                                transform="translateY(-50%)"
                                onClick={() => slider.current?.prev()}
                                icon={<IoChevronBackOutline/>}
                                aria-label="Previous Slide"
                            />
                        )}

                        {hasNext && (
                            <CarouselIconButton
                                pos="absolute"
                                right="3"
                                top="50%"
                                transform="translateY(-50%)"
                                onClick={() => {
                                    slider.current?.next()
                                }}
                                icon={<IoChevronForwardOutline/>}
                                aria-label="Next Slide"
                            />
                        )}
                        <HStack position="absolute" width="full" justify="center" bottom="0" py="4">
                            {images.map((_, index) => (
                                <Circle key={index} size="2" bg={currentSlide === index ? 'white' : 'whiteAlpha.400'}/>
                            ))}
                        </HStack>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

const CarouselIconButton = (props: IconButtonProps) => (
    <IconButton
        display="none"
        fontSize="lg"
        isRound
        boxShadow="base"
        bg={useColorModeValue('white', 'gray.800')}
        _hover={{
            bg: useColorModeValue('gray.100', 'gray.700'),
        }}
        _active={{
            bg: useColorModeValue('gray.200', 'gray.600'),
        }}
        _focus={{boxShadow: 'inerhit'}}
        _focusVisible={{boxShadow: 'outline'}}
        {...props}
    />
)