import {Container, Stack, Text} from '@chakra-ui/react'
import React from "react";
import {Logo} from "./Logo";
// import {FaGithub, FaLinkedin, FaTwitter} from 'react-icons/fa'

export const FooterWithSocialIcons = () => (
    <Container as="footer" role="contentinfo" py={{base: '8', md: '8'}} minW={'full'}>
        <Stack spacing={{base: '4', md: '5'}}>
            <Stack justify="space-between" direction={{base: 'column', md: 'row'}} align="center">
                <Logo/>
                {/*<ButtonGroup variant="tertiary">*/}
                {/*    <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin/>}/>*/}
                {/*    <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub/>}/>*/}
                {/*    <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter/>}/>*/}
                {/*</ButtonGroup>*/}
                <Text fontSize="sm" color="fg.subtle">
                    &copy; {new Date().getFullYear()} BedDividerForCouples. All rights reserved.
                </Text>
            </Stack>
        </Stack>
    </Container>
)
