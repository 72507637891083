import type {ChangeEvent} from "react";
import React from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    useColorModeValue
} from '@chakra-ui/react';
import {useFormData} from "../../context/formDataContext";
import {EmailIcon} from "@chakra-ui/icons";

export const SubscribeForm = ({onClose, color, size, showTextarea, showSubmitText}: {
    onClose: any,
    color?: string,
    size?: string,
    showTextarea?: boolean
    showSubmitText?: boolean
}) => {
    const {updateFormData} = useFormData();
    const mode = useColorModeValue('blue.500', 'blue.700')
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const myForm = event.target;
        const formData = new FormData(myForm);

        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            // @ts-ignore
            body: new URLSearchParams(formData).toString(),
        })
            .then(() => {
                console.log("Form successfully submitted")
                onClose();
            })
            .catch((error) => alert(error));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const {name, value} = event.target;
        updateFormData(name, value); // Update the shared form data
    };

    return (
        <form name="contact" method="POST" action={"/?index"} data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact"/>
            <input type="hidden" name="color" value={color}/>
            <input type="hidden" name="size" value={size}/>
            <Stack spacing="4">
                <FormControl isRequired>
                    <FormLabel htmlFor="email">Enter your email</FormLabel>
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <EmailIcon color='gray.300' bg={'#fff'} pos={'relative'} top={'5px'}/>
                        </InputLeftElement>
                        <Input id="email" type="email" name="email" required size="lg"
                               focusBorderColor={mode}
                               borderColor={'gray.400'}
                               fontSize="md"
                               style={{paddingLeft: '2rem'}}
                               onChange={handleInputChange}/>
                    </InputGroup>
                    {showTextarea && (
                        <InputGroup mt={2}>
                            <Input id="textarea" type="textarea" name="textarea" required size="lg"
                                   focusBorderColor={mode}
                                   borderColor={'gray.400'}
                                   placeholder={'Message...'}
                                   fontSize="md"
                                   height={'5rem'}
                                   style={{paddingLeft: '2rem'}}
                                   onChange={handleInputChange}/>
                        </InputGroup>
                    )
                    }
                </FormControl>
                <Button
                    type="submit"
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="md"
                    colorScheme="blue"
                    bg={'blue.800'}
                    size="lg"
                    rounded="full"
                >
                    {showSubmitText ? 'Submit' : 'Sign up'}
                </Button>
            </Stack>
        </form>
    );
};
