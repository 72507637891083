export const jobListings = [
    {
        title: "How does the HealthShield Bed Divider help with snoring?",
        description: "Our bed divider is designed to create a physical barrier that helps reduce the impact of snoring noise. While it does not stop snoring, it diminishes the noise level, leading to a more peaceful sleep for non-snoring partners."
    },
    {
        title: "Is the bed divider easy to install and remove?",
        description: "Yes, the HealthShield Bed Divider is designed for ease of use. It can be easily installed and removed simply by folding it for daily use or when it's no longer needed."
    },
    {
        title: "Can the divider prevent the spread of germs?",
        description: "The divider is effective in creating a physical separation between partners, which can help minimize the spread of germs and illness, especially in cases where one partner is sick."
    },
    {
        title: "Will the divider fit any bed size?",
        description: "Our bed divider is designed to be versatile and can fit any bed size. We offer two sizes: Standard and Large"
    },
    {
        title: "How do I clean and maintain the bed divider?",
        description: "The divider is designed for easy maintenance. It can be cleaned with a damp cloth for minor stains, spots, or dusted."
    },
    {
        title: "Can I return the bed divider if it doesn't meet my needs?",
        description: "We offer a customer-friendly return policy. If the bed divider does not meet your expectations, you can return it within a specified period for a refund or exchange. Please refer to our return policy for more details."
    }
]

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
        infer ElementType
    >
    ? ElementType
    : never

export type JobListing = ElementType<typeof jobListings>
